import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Jaffe Engineering</h1>
    <p>
        <em>Please note:</em> This page is under construction.
    </p>
  </Layout>
)

export default IndexPage
